import { Link, useParams } from '@remix-run/react';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { match, P } from 'ts-pattern';

import {
  type DtoEvent,
  EnumsEventType,
  EnumsWebRTCTestSource,
} from '@lp-lib/api-service-client/public';

import { GamePackDetailsDuringExperience } from '../../../app/components/GamePack/Details/GamePackDetailsDuringExperience';
import { useTechTestAnalytics } from '../../analytics/techTest';
import supportAvatar from '../../assets/img/support-avatar.png';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useTitle } from '../../hooks/useTitle';
import { ConnectionTest } from '../../pages/ConnectionTest';
import { GamePackContextProvider } from '../../pages/GamePack/Context';
import { apiService } from '../../services/api-service';
import { type WebRTCTestResult } from '../../services/webrtc';
import { fromDTOGamePack } from '../../utils/api-dto';
import { err2s, makeTitle } from '../../utils/common';
import { CopyButton } from '../common/CopyButton';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { GameCenterContextProvider } from '../Game/GameCenter';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';
import { CloseIcon } from '../icons/CloseIcon';
import { MinusIcon } from '../icons/MinusIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { QuestionIcon } from '../icons/QuestionIcon';
import { Loading } from '../Loading';
import { useMyOrgId } from '../Organization/hooks/organization';
import { resolveRandomizationSettings } from '../TeamRandomizer';
import { useUser } from '../UserContext';
import { EventRow } from './EventRow';
import { useEvent } from './useEvent';

function ConnectionTestModal(props: {
  onClose: () => void;
  onCompleted: (result: WebRTCTestResult) => void;
}) {
  const orgId = useMyOrgId();
  return (
    <ModalWrapper
      containerClassName='w-240 h-135 relative'
      innerClassName='overflow-hidden'
      borderStyle='gray'
    >
      <div>
        <button
          type='button'
          className='btn absolute right-3 top-3 w-6 h-6 rounded-full flex
              justify-center items-center border border-secondary bg-black z-[55]'
          onClick={props.onClose}
        >
          <CloseIcon className='w-2 h-2 fill-current' />
        </button>
        <ConnectionTest
          customizeFinishStep
          orgId={orgId}
          onCompleted={props.onCompleted}
          reCaptchaEnabled={false}
        />
      </div>
    </ModalWrapper>
  );
}

function useTechTest() {
  const analytics = useTechTestAnalytics();

  const swr = useSWR('/network-test/last-result', async () => {
    const resp = await apiService.misc.getLastTestResult();
    return resp.data;
  });

  const user = useUser();
  const {
    state: { state: reportState },
    call: report,
  } = useLiveAsyncCall(async (result: WebRTCTestResult) => {
    if (!result) {
      alert('invalid result');
      return;
    }
    await apiService.misc.reportTestResult({
      id: result.id,
      from: window.location.href,
      source: EnumsWebRTCTestSource.WebRTCTestSourceEvent,
      email: user.email ?? '',
      orgId: user.organizer?.orgId,
      results: [result],
    });
  });

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const onClick = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ConnectionTestModal
          onClose={p.internalOnCancel}
          onCompleted={async (result) => {
            await report(result);
            await Promise.all([
              swr.mutate(),
              analytics.trackTechTestTaken({ succeeded: result.succeeded }),
            ]);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };

  const DURATION_30DAYS_MS = 30 * 24 * 60 * 60 * 1000;
  const olderThan30Days = (t: string | null) =>
    t && new Date(t).getTime() < Date.now() - DURATION_30DAYS_MS;
  const within30Days = (t: string | null) =>
    t && new Date(t).getTime() >= Date.now() - DURATION_30DAYS_MS;
  const format = (t: string | null) =>
    t && new Intl.DateTimeFormat().format(new Date(t));

  // Lots of permutations here:
  // - succeeded: true, recent
  // - succeeded: true, stale
  // - succeeded: false, recent | stale
  // - nullish, recent | stale | nullish

  // To test these various states easily:
  // 1. take the test
  // 2. docker compose up redis-ui
  // 3. open the port in a browser, add a connection to "redis-server"
  // 4. twiddle the values as needed.

  const connectionTestUrl = new URL(window.location.origin);
  connectionTestUrl.pathname = '/connection-test';

  const result = match(swr.data)
    .with({ succeeded: true, createdAt: P.when(within30Days) }, (m) => {
      return {
        priority: 'low',
        element: (
          <div className='p-3.5 flex justify-between text-white'>
            <dl>
              <dt className='text-green-001 text-base font-bold'>
                Your tech test passed on {format(m.createdAt)}!
              </dt>
              <dd className='text-sms'>You're all set for your event!</dd>
            </dl>
            <div className=''>
              <aside className='flex gap-4 justify-end'>
                <span className='text-sms w-62 text-right'>
                  Want to share the test with a coworker or retake it yourself?
                </span>
                <div className='flex flex-col gap-1 relative'>
                  <CopyButton
                    className='btn-secondary h-10 w-38'
                    copiedText={connectionTestUrl.toString()}
                  >
                    Copy Link
                  </CopyButton>
                  <button
                    type='button'
                    className='absolute left-0 right-0 -bottom-5 w-full text-xs text-icon-gray text-center'
                    onClick={onClick}
                    disabled={reportState.isRunning}
                  >
                    (Take again)
                  </button>
                </div>
              </aside>
            </div>
          </div>
        ),
      };
    })
    .with({ succeeded: true, createdAt: P.when(olderThan30Days) }, (m) => {
      return {
        priority: 'low',
        element: (
          <div className='p-3.5 flex justify-between text-white'>
            <dl>
              <dt className='text-icon-gray text-base font-bold'>
                You took the tech test on {format(m.createdAt)}
              </dt>
              <dd className='text-sms'>
                If your computer or network setup has changed, you can take it
                again anytime
              </dd>
            </dl>
            <div className=''>
              <aside className='flex gap-4 justify-end'>
                <button
                  type='button'
                  className='btn-secondary h-10 w-38'
                  onClick={onClick}
                  disabled={reportState.isRunning}
                >
                  Retake Test
                </button>
              </aside>
            </div>
          </div>
        ),
      };
    })
    .with({ succeeded: false, createdAt: P._ }, (m) => {
      return {
        priority: 'high',
        element: (
          <div className='p-8 flex justify-between text-white bg-dark-gray rounded-xl'>
            <dl>
              <dt className='text-red-006 text-base font-bold'>
                {m.createdAt
                  ? `Your tech test failed on ${format(m.createdAt)}`
                  : `Your tech test failed`}
              </dt>
              <dd className='text-sms'>
                Please refer to our{' '}
                <a
                  href='https://narvii.notion.site/Troubleshooting-VPN-Firewall-Issues-ce9eed94f5c04bf1bcf4359162187d9f'
                  target='_blank'
                  rel='noreferrer'
                  className='underline'
                >
                  VPN & Firewall Issues Guide
                </a>{' '}
                for more information
              </dd>
            </dl>
            <div className=''>
              <aside className='flex gap-4 justify-end'>
                <span className='text-sms w-62 text-right'>
                  Want to share the test with a coworker or retake it yourself?
                </span>
                <div className='flex flex-col gap-1 relative'>
                  <CopyButton
                    className='btn-secondary h-10 w-38'
                    copiedText={connectionTestUrl.toString()}
                  >
                    Copy Link
                  </CopyButton>
                  <button
                    type='button'
                    className='absolute left-0 right-0 -bottom-5 w-full text-xs text-icon-gray text-center'
                    onClick={onClick}
                    disabled={reportState.isRunning}
                  >
                    (Take again)
                  </button>
                </div>
              </aside>
            </div>
          </div>
        ),
      };
    })
    .otherwise(() => {
      return {
        priority: 'high',
        element: (
          <div className='p-8 flex justify-between text-white bg-dark-gray rounded-xl ring-1 ring-tertiary'>
            <dl>
              <dt className='text-tertiary text-base font-bold'>
                Required: Take our Tech Test
              </dt>
              <dd className='text-sms'>
                Take the short tech test to ensure everything will work properly
              </dd>
            </dl>
            <div className=''>
              <aside className='flex gap-4 justify-end'>
                <button
                  type='button'
                  className='
                      btn
                      text-white
                      h-10 w-45
                      bg-gradient-to-tr from-yellow-start to-yellow-end text-center
                    '
                  onClick={onClick}
                  disabled={reportState.isRunning}
                >
                  Take Tech Test
                </button>
              </aside>
            </div>
          </div>
        ),
      };
    });

  return {
    ...result,
    element: swr.isLoading ? null : result.element,
  };
}

function StepDownloadAssets(props: { event: DtoEvent }) {
  const media =
    props.event.gamePack.marketingSettings?.sharableMarketingMaterials?.media;
  const url = media
    ? `${window.origin}/media/${media.id}`
    : 'https://youtu.be/i-kk2hoy9R0';
  const copiedText = `Hey team, just a reminder we’ll be having a Luna Park experience in just a few days. Here’s a video to get you excited for what’s to come! ${url}`;

  return (
    <div className='w-full h-25 flex items-center gap-5 bg-dark-gray rounded-xl px-5'>
      <div className='flex flex-col flex-grow gap-0.5'>
        <div className='text-tertiary font-bold'>
          Prep your Attendees by Sharing this Video
        </div>
        <div className='text-sms'>
          Copy this content and share it with your team
        </div>
      </div>
      <CopyButton copiedText={copiedText}>
        <div className='h-10 w-45 btn-primary flex items-center justify-center'>
          Copy
        </div>
      </CopyButton>
    </div>
  );
}

function RunOfShow(props: {
  timeLabel: string;
  title: string;
  disabled?: boolean;
  children?: ReactNode;
}) {
  if (props.disabled) return null;
  return (
    <div className='w-full flex'>
      <div className='w-25 flex-shrink-0 font-bold italic text-icon-gray'>
        {props.timeLabel}
      </div>
      <div className='flex flex-col flex-grow gap-3.5'>
        <div className='font-bold'>{props.title}</div>
        {props.children}
      </div>
    </div>
  );
}

function NumberBadge(props: { val: number }) {
  return (
    <span className='w-9.5 h-5 rounded-1.5lg bg-[#8C6FFF] flex items-center justify-center mx-0.5 text-sms font-bold'>
      {props.val}
    </span>
  );
}

function Expandable(props: {
  children?: ReactNode;
  expand?: boolean;
  durationMs?: number;
}) {
  return (
    <div
      className={`grid transition-all`}
      style={{
        gridTemplateRows: props.expand ? '1fr' : '0fr',
        transitionDuration: `${props.durationMs ?? 500}ms`,
      }}
    >
      <div className='overflow-hidden'>{props.children}</div>
    </div>
  );
}

function StepRunOfShow(props: { event: DtoEvent }) {
  const { event } = props;
  const [showDetail, setShowDetail] = useState(true);
  const playersCount = (event.attendees || []).length + 1;
  const teamSize = useMemo(
    () =>
      resolveRandomizationSettings(
        event.gamePack.teamRandomizationSettings,
        playersCount
      ).teamSize,
    [event.gamePack.teamRandomizationSettings, playersCount]
  );
  const gameMins = Math.round(event.gamePack.approximateDurationSeconds / 60);
  const gameStartMins = teamSize === 0 ? 2 : 3;
  const gameEndMins =
    gameMins > gameStartMins ? gameMins : gameStartMins + gameMins;
  return (
    <div className='w-full flex flex-col bg-dark-gray rounded-xl px-5'>
      <div className='w-full pt-7 pb-6 flex justify-between gap-5'>
        <div className='flex flex-col flex-grow gap-0.5'>
          <div className='text-tertiary font-bold'>Run of Show</div>
          <div className='text-sms'>Expand to see the full run of show</div>
        </div>
        {event.type === EnumsEventType.EventTypeOnd && (
          <div className='flex flex-col gap-1.5'>
            <div className='text-white font-bold'>
              Want to see it in action?
            </div>
            <Link
              to={`/events/${event.id}/play`}
              className='text-primary text-sms text-center'
            >
              Demo your experience here
            </Link>
          </div>
        )}
      </div>
      <div className='flex flex-col gap-2.5'>
        <RunOfShow timeLabel='0-2 Mins' title='Gather and Mingle'>
          <Expandable expand={showDetail}>
            <div className='text-sms'>
              {event.type === EnumsEventType.EventTypeOnd ? (
                <>
                  Teams gather in our lobby to mingle. Once ready, the
                  Organizer, who scheduled the event, presses 'Start' to begin.
                </>
              ) : (
                <>
                  Teams gather in our lobby to mingle. The host will be there to
                  welcome everyone and begin the experience within 5 minutes of
                  the start time.
                </>
              )}
            </div>
          </Expandable>
        </RunOfShow>
        {teamSize > 0 && (
          <RunOfShow timeLabel='2-3 Mins' title='Randomize Teams'>
            <Expandable expand={showDetail}>
              <div className='text-sms'>
                You will be randomized into teams. Team size is based on the
                total number of attendees that join the experience.
                <div className='flex items-center'>
                  <div>• For your group of </div>
                  <NumberBadge val={playersCount} />
                  <div>, we'll break everyone into teams of </div>
                  <NumberBadge val={teamSize} />
                </div>
              </div>
            </Expandable>
          </RunOfShow>
        )}
        <RunOfShow
          timeLabel={`${gameStartMins}-${gameEndMins} Mins`}
          title='Game Play'
        >
          <Expandable expand={showDetail}>
            <div>
              <div className='text-sms'>{event.gamePack.description}</div>
              <GameCenterContextProvider>
                <GamePackContextProvider embed={true} pageType='public'>
                  <GamePackDetailsDuringExperience
                    pack={fromDTOGamePack(event.gamePack)}
                  />
                </GamePackContextProvider>
              </GameCenterContextProvider>
            </div>
          </Expandable>
        </RunOfShow>
        <RunOfShow timeLabel='Post Event' title='Memories Image Sent'>
          <Expandable expand={showDetail}>
            <div className='text-sms'>
              A memories page will be generated at the end of the game which
              shows a scoreboard and images of the team. This memories page is
              always accessible and easily shared to your team.
            </div>
          </Expandable>
        </RunOfShow>
        <div className='w-full flex items-center justify-center'>
          <button
            type='button'
            className='btn h-10 text-icon-gray text-sms'
            onClick={() => setShowDetail(!showDetail)}
          >
            Show {showDetail ? 'Less' : 'Details'}
          </button>
        </div>
      </div>
    </div>
  );
}

function FAQItem(props: {
  faq: { question: string; answer: string | ReactNode };
}) {
  const { faq } = props;
  const [opened, setOpened] = useState(false);
  return (
    <div
      className={`bg-dark-gray rounded-xl p-5 flex flex-col cursor-pointer`}
      onClick={() => setOpened(!opened)}
    >
      <div className='w-full flex items-center justify-between'>
        <div className='text-sms'>{faq.question}</div>
        <div className='text-icon-gray w-6 h-6'>
          {opened ? (
            <MinusIcon className='w-6 h-[3px] fill-current' />
          ) : (
            <PlusIcon className='w-6 h-6 fill-current' />
          )}
        </div>
      </div>
      <Expandable expand={opened} durationMs={200}>
        <div className='h-4'></div>
        <div className={`text-sms`}>{faq.answer}</div>
      </Expandable>
    </div>
  );
}

function FAQList(props: { initiallyOpen: boolean }) {
  const faqs = [
    {
      question: 'What do we need to do before the experience?',
      answer: (
        <>
          Before you get started, please complete a quick tech test to ensure a
          seamless experience. If your team works both remotely and in-office,
          have one person from each setup try it.{' '}
          <Link
            to='/connection-test'
            className='text-primary'
            onClick={(e) => e.stopPropagation()}
          >
            You can access that here.
          </Link>
        </>
      ),
    },
    {
      question: 'Does Luna Park accommodate a global audience?',
      answer: (
        <>
          Luna Park experiences are designed to be inclusive to all. We have a
          wide array of offerings that appeal to many different cultures and the
          majority of our experiences do not require familiarity with any
          particular culture.{' '}
          <div className='italic'>
            Note: all of our experiences are hosted in English today but have
            written instructions on screen as well.
          </div>
        </>
      ),
    },
    {
      question: 'Do we need to organize into teams before the experience?',
      answer:
        'No! Luna Park is a completely turnkey solution and we’ll take care of randomly assigning teams at the beginning of the experience. This ensures that your team members will connect with colleagues outside of their core groups. Team sizes will be determined by the total number of participants but they will never be larger than 6 team members.',
    },
    {
      question: 'Does anyone need to host the experience?',
      answer:
        'No! On Luna Park we facilitate everything to ensure that each team member, inclusive of the organizer, can participate alongside their colleagues. This means all you have to do is show up on gameday, press start, and we take care of the rest.',
    },
    {
      question: 'Will we get a photo of everyone afterward?',
      answer:
        'Absolutely! After every single experience on Luna Park you’ll have access to a “Memories” page that will include a custom created, shareable GIF of the team members that participated in that experience. ',
    },
    {
      question: 'How do we access the event?',
      answer:
        'All Luna Park experiences happen in the web browser so there are no apps to installs or software to download. You should not be on Zoom, Google Meet, Teams or any other connection software when accessing Luna Park. You and your colleagues will be able to access the event from the link shared in the calendar invite that you created when scheduling your event.',
    },
    {
      question: 'What technical requirements are there for participants?',
      answer:
        'Each participant should use their own modern Laptop or Desktop (no phones/tablets). We recommend having a strong internet connection and using the Chrome, Firefox, or Edge browser. You should not be on Zoom, Google Meet, Teams or any other connection software when accessing Luna Park. You should turn off any VPNs or white list the Luna Park domain.',
    },
    {
      question: 'How should we prepare participants for the event?',
      answer: (
        <>
          If it is your team members' first time on Luna Park we recommend
          sharing{' '}
          <a
            href='https://youtu.be/i-kk2hoy9R0'
            target='_blank'
            rel='noreferrer'
            className='text-primary'
            onClick={(e) => e.stopPropagation()}
          >
            this video
          </a>
          , which provides your team a brief overview of the platform and helps
          set them up for success.
        </>
      ),
    },
    {
      question:
        'What happens if someone has technical difficulties during the event?',
      answer:
        'We always recommend that you try a quick refresh. If that doesn’t work just tap the Help button in the bottom left of the screen. One of our Luna Park team members will provide immediate assistance to help improve the situation.',
    },
    {
      question: 'What do I do on the event day?',
      answer:
        'On the event day, participants will join through their calendar link. Once you click “Start”, your group will be automatically randomized into teams, and the event will begin!',
    },
    {
      question: 'What if I have to cancel or change the time of the event?',
      answer:
        'You can always cancel or edit the time and date of your event and you can do this in several places: 1) The calendar invite contains a link that will lead you to a page to modify your event details. 2) You can always go to your my events page and select the event you want to edit.',
    },
  ];

  const [open, setOpen] = useToggler(props.initiallyOpen);

  return (
    <div className='w-full flex flex-col gap-4'>
      <button
        type='button'
        className='text-xl font-bold flex gap-4'
        onClick={() => setOpen((v) => !v)}
      >
        Review these FAQs{' '}
        {open ? (
          <ArrowUpIcon className='w-7 h-7 fill-current' />
        ) : (
          <ArrowDownIcon className='w-7 h-7 fill-current' />
        )}{' '}
      </button>
      <div className={`flex flex-col gap-1 ${open ? '' : 'hidden'}`}>
        {faqs.map((faq, index) => (
          <FAQItem key={index} faq={faq} />
        ))}
      </div>
    </div>
  );
}

function useToggler(externallyOpened: boolean) {
  const [open, setOpen] = useState(externallyOpened);
  useEffect(
    // Keep open if open, but otherwise track if externallyOpened goes from
    // false -> true
    () => setOpen((v) => (v ? v : externallyOpened)),
    [externallyOpened]
  );

  return [open, setOpen] as const;
}

function StepList(props: { event: DtoEvent; initiallyOpen: boolean }) {
  const [open, setOpen] = useToggler(props.initiallyOpen);
  return (
    <div className='w-full flex flex-col gap-4'>
      <button
        type='button'
        className='text-xl font-bold flex gap-4'
        onClick={() => setOpen((v) => !v)}
      >
        What To Expect During Your Event
        {open ? (
          <ArrowUpIcon className='w-7 h-7 fill-current' />
        ) : (
          <ArrowDownIcon className='w-7 h-7 fill-current' />
        )}{' '}
      </button>
      <div className={`w-full flex flex-col gap-4 ${open ? '' : 'hidden'}`}>
        <StepDownloadAssets event={props.event} />
        <StepRunOfShow event={props.event} />
        <HelpNotice />
      </div>
    </div>
  );
}

function HelpNotice() {
  return (
    <div className='flex items-center mt-5 gap-8'>
      <div className='w-20 h-20 relative'>
        <img src={supportAvatar} alt='avatar' className='w-full h-full' />
        <div
          className='
            absolute text-icon-gray -right-3.5 bottom-0 bg-black
            rounded-full w-10 h-10 flex items-center justify-center
          '
        >
          <QuestionIcon />
        </div>
      </div>
      <div className='flex flex-col gap-2'>
        <div className='font-extrabold'>Need help during the game?</div>
        <div className='text-sms'>
          Access live support by hitting the “Help” button at any time
        </div>
      </div>
    </div>
  );
}

function EventDetailInternal(props: {
  event: DtoEvent;
  techTest: ReturnType<typeof useTechTest>;
}) {
  const { event, techTest } = props;
  useTitle(makeTitle(`Event Scheduled - ${event.gamePack.name}`));

  return (
    <div className='w-full flex flex-col gap-5'>
      <header>
        <div className='text-3.5xl font-bold'>Your Event is Scheduled! 🎉</div>
      </header>
      {techTest?.priority === 'high' ? techTest.element : null}
      <EventRow event={event} />
      {techTest?.priority === 'low' ? techTest.element : null}
      <StepList event={event} initiallyOpen={techTest.priority === 'low'} />
      <FAQList initiallyOpen={techTest.priority === 'low'} />
    </div>
  );
}

function useEventDetailLoader() {
  const { eventId } = useParams<'eventId'>();
  const swr = useEvent(eventId);
  const techTest = useTechTest();
  return { event: swr, techTest };
}

export function EventDetail() {
  const data = useEventDetailLoader();

  return (
    <div className='h-full flex-1 overflow-y-auto scrollbar bg-game-library bg-w-full bg-no-repeat bg-top bg-local flex justify-center'>
      <div className='w-245 text-white'>
        <main className='w-full py-10 flex justify-center'>
          {match(data)
            .with(
              { event: { isLoading: true } },
              { techTest: { element: P.nullish } },
              () => <Loading />
            )
            .with({ event: { error: P.not(P.nullish) } }, (d) => (
              <div className='text-sms text-red-002'>
                {err2s(d.event.error)}
              </div>
            ))
            .with({ event: { data: P.nullish } }, () => (
              <div className='text-sms text-red-002'>Event not found</div>
            ))
            .otherwise(() => (
              <EventDetailInternal
                event={data.event.data as unknown as DtoEvent}
                techTest={data.techTest}
              />
            ))}
        </main>
      </div>
    </div>
  );
}
