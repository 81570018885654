import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { type DtoGetTestResultResponse } from '@lp-lib/api-service-client/public';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

type AnalyticsTechTestProperties = DtoGetTestResultResponse;

class TechTestAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    private readonly orgId: string | null
  ) {}

  trackTechTestTaken(props: AnalyticsTechTestProperties) {
    this.analytics.track('Tech Test Taken', this.buildEventProps(props));
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useTechTestAnalytics() {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new TechTestAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
